(function($) {
    $(window).load(function() {
        //Carrousel page d'accueil
        $('.owl-carousel.owl-carrousel-slideshow').each(function(){
            var $t = $(this);
            var willBeAnimated = $t.find('.slideshow__item__wrapper').length > 1;

            var isAutoplay =  $t.attr("autoplay") && willBeAnimated;
            var isAutoplayHoverPause = $t.attr("autoplayHoverPause");
            var autoplayTimeoutAttr = $t.attr("autoplayTimeout");
            if(isNaN(autoplayTimeoutAttr)){
                autoplayTimeoutAttr = "5000";
            }
            var smartSpeedAttr = $t.attr("smartSpeed");
            if(isNaN(smartSpeedAttr)){
                smartSpeedAttr = "200";
            }

            $t.owlCarousel({
                items: 1,
                margin: 0,
                loop: willBeAnimated,
                nav: false,
                autoplay: isAutoplay,
                mouseDrag: willBeAnimated,
                touchDrag: willBeAnimated,
                autoplayTimeout: autoplayTimeoutAttr,
                autoplayHoverPause: isAutoplayHoverPause,
                smartSpeed: smartSpeedAttr,
                dotsContainer: ".nav_container"
            });
            $t.nextAll('.owl-controls').prepend('<button class="owl-prev"></button>').append('<button class="owl-next"></button>');
            $t.nextAll('.owl-controls').find('.owl-prev').click(function(){
                $t.trigger('prev.owl.carousel');
            });
            $t.nextAll('.owl-controls').find('.owl-next').click(function(){
                $t.trigger('next.owl.carousel');
            });
            $t.nextAll('.owl-controls').insertAfter($t.children().last());
        });
    });
})(jQuery.noConflict());
