/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: frontgen
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function (jQuery) {

    window.LOCALE_BO = window.LOCALE_BO || {};

    jQuery.extend(true, window.LOCALE_BO, {
        supprimer: "Supprimer",
        ajouter: "Ajouter",
        valider: "Valider",
        archiver: "Archiver",
        consulter: "Consulter",
        modifier: "Modifier",
        nouvelleFenetre: "(Nouvelle fenêtre)",
        selectionner: "Sélectionner",
        confirmer: "Confirmer",
        fermer: "Non",
        erreur: "Erreur",
        ok: "Oui",
        confirmQuitterPage: "Êtes-vous sûr de vouloir quitter cette page&nbsp;?",
        confirmSupprFiche: "La suppression de cette fiche peut impacter les URLs de rubrique et de ses contenus. Êtes-vous sûr de vouloir supprimer cette fiche&nbsp;?",
        selectionnerUnELement: "Veuillez sélectionner au moins un élément avant de lancer une action.",
        confirmSupprFiches: "La suppression de ces fiches peut impacter les URLs de rubrique et de ses contenus. Êtes-vous sûr de vouloir supprimer ces fiches&nbsp;?",
        confirmSupprUtilisateur: "Êtes-vous sûr de vouloir supprimer cet utilisateur&nbsp;?",
        confirmSupprUtilisateurs: "Êtes-vous sûr de vouloir supprimer ces utilisateurs&nbsp;?",
        confirmSupprRubrique: "Êtes-vous certain de vouloir supprimer la rubrique &quot;{0}&quot; ainsi que son arborescence et tous ses contenus ? Attention, seules les fiches et leurs plugins vont être supprimés.<br/>Cette action est définitive.",
        confirmArchFiches: "L'archivage de ces fiches peut impacter les URLs de rubrique et de ses contenus. Êtes-vous sûr de vouloir archiver ces fiches&nbsp;?<br>(Seules les fiches en ligne seront archivées.)",
        lExtension: "L'extension",
        leModule: "Le module",
        patienter: "<br/>Merci de bien vouloir patienter&hellip;",
        voirEnLigne: "Voir en ligne",
        actions: "Actions",
        erreurRecherche: "Une erreur s'est produite lors du calcul de vos résultats de recherche.",
        erreurActionMasse: "Une erreur s'est produite lors du traitement de votre action.",
        confirmationActionMasseSuppr: "Les fiches sélectionnées ont bien été supprimées.",
        confirmationActionMasseArchi: "Les fiches &quot;En ligne&quot; ont bien été archivées.",
        confirmationActionMasseUtilisateur: "Les utilisateurs sélectionnés ont bien été supprimés.",
        activer: "Activer",
        desactiver: "Désactiver",
        captchaError: "Le mot saisi est invalide",

        // Etats
        enCoursSuppression: "est en cours de suppression.",
        enCoursRechargement: "Le rechargement est en cours.",
        enSuccesSuppression: "La suppression a bien été effectuée",
        enErreurSuppression: "Une erreur est survenue lors de la suppression",
        enCoursdActivation: "est en cours d'activation.",
        enSuccesActivation: "L'activation a bien été effectuée",
        enErreurActivation: "Une erreur est survenue lors de l'activation",
        enCoursdeDesactivation: "est en cours de désactivation.",
        enSuccesDesactivation: "La désactivation a bien été effectuée",
        enErreurDesactivation: "Une erreur est survenue lors de la désactivation",
        enSuccesRechargement: "Le rechargement a bien été effectué",
        enErreurRechargement: "Une erreur est survenue lors du rechargement",
        enSucces: "Succès",
        enErreur: "Erreur",

        // Validations
        validationMessages: {
            ksupDate: "Veuillez fournir une date valide",
            ksupPhone: "Veuillez fournir un numéro de téléphone valide",
            pwdVerdicts: ["Faible", "Normal", "Moyen", "Fort", "Très fort"],
            pwdErrorMessages: {
                password_too_short: "Trop court",
                email_as_password: "N'utilisez pas votre email comme mot de passe",
                same_as_username: "Votre mot de passe ne doit pas contenir votre identifiant",
                two_character_classes: "Variez la casse",
                repeated_character: "Trop de répétitions",
                sequence_found: "Votre mot de passe contient des séquences"
            }
        },

        // Services
        services: {
            arbre: {
                indisponible: "Le service de gestion d'arborescence est actuellement indisponible. Aucune modification ne sera prise en compte.",
                confirmerSuppression: "Êtes-vous sûr de vouloir supprimer {0} ?"
            },
            auto_complete: {
                search: "Recherche en cours&hellip;",
                noresult: "Pas de résultat"
            }
        },

        // Popins
        popin: {
            title: {
                generique: "Veuillez sélectionner un élément",
                structure: {
                    mono: "Sélectionner une structure",
                    multi: "Sélectionner des structures"
                },
                rubrique: {
                    mono: "Sélectionner une rubrique",
                    multi: "Sélectionner des rubriques"
                },
                groupe: {
                    mono: "Sélectionner un groupe",
                    multi: "Sélectionner des groupes"
                },
                utilisateur: "Sélectionner un utilisateur",
                objet: "Sélectionner une fiche",
                pagelibre: "",
                photo: "Ajouter une image",
                fichier: "Ajouter un fichier"
            }
        },

        //Médias
        media: {
            player: {
                mute: "Activer/Désactiver le son",
                playPause: "Lire/Mettre en pause",
                fullscreen: "Plein écran",
                tracks: "Sous-titres",
                postRoll: "Fermer"
            }
        },

        // saisie front
        kselect: {
            mono: {
                select: "Sélectionner",
                clear: "Effacer"
            },
            multi: {
                parentLabel: "Item(s) disponible(s)",
                compositionLabel: "Item(s) sélectionné(s)"
            }
        },

        libelle: {
            inconnu: "Libellé inconnu"
        },

        // CKEditor
        ckeditor: {
            anchor: {
                alert: "Le nom doit commencer par une lettre et ne doit contenir que les caractères suivant : A-Z, a-z, 0-9, :-_."
            },
            notifications: {
                spellcheker: "La correction orthographique est accessible via <kbd title=&quot;Controle&quot;>Ctrl</kbd> + clic droit"
            },
            plugins: {
                listeFiche: {
                    title: "Liste de fiches",
                    insert: "Insérer une liste de fiches"
                },
                link: {
                    title: "Insérer un lien",
                    anchor: {
                        tip: {
                            alert: "Aucune ancre n'a été détectée dans la page en cours d'édition."
                        }
                    }
                },
                kflipbook: {
                    title: "Insérer un Flipbook",
                    menu: "Propriétés du Flipbook"
                },
                kimage: {
                    title: "Insérer une image",
                    menu: "Propriétés de l'image"
                },
                kvideo: {
                    title: "Insérer une vidéo",
                    menu: "Propriétés de la vidéo"
                },
                kaudio: {
                    title: "Insérer de l'audio",
                    menu: "Propriétés de l'audio"
                },
                kflash: {
                    title: "Insérer une animation Flash",
                    menu: "Propriétés de l'animation Flash"
                },
                kpdfviewer: {
                    title: "Insérer un PDF",
                    menu: "Propriétés du PDF"
                },
                ktag: {
                    title: "Tags",
                    menu: "Propriétés du tag"
                }
            }
        }
    });

    window.LOCALE_FO = window.LOCALE_FO || {};

    jQuery.extend(true, window.LOCALE_FO, {
        // Popins
        popin: {
            title: {
                dossier: {
                    mono: "Sélectionner un dossier"
                }
            }
        },
        // Galerie
        galerie: {
            controls: {
                next: "Suivant (flêche de droite)",
                previous: "Précédent (flêche de gauche)",
                close: "Fermer (Esc)"
            },
            display: {
                counter: "sur",
                loading: "Chargement&hellip;",
                errorIFrame: "Une erreur est survenue lors du chargement de l'élément",
                errorImage: "Une erreur est survenue lors du chargement de l'image",
                errorFormatInconnu: "Ce format de fichier n'est pas supporté par la visionneuse"
            }
        },
        // Menu
        menu: {
            retour: "Retour"
        },
        search: {
            search_by_keyword: "Recherche par mots-clés",
            no_result: "Aucune proposition de résultat",
            wait_result: "Recherche de résultats...",
            query_too_short: "Taper 3 caractères ou plus pour obtenir des résultats.",
            selected_option: "L'option sélectionnée est surlignée.",
            assistive_hint: "Lorsque les résultats d'autocomplétion sont disponibles, utilisez les flèches haut et bas pour faire défiler et entrer pour sélectionner. Utilisateurs d'appareils tactiles, explorer par le toucher ou avec les gestes de balayage."
        },
        tooltip: {
            char_remaining: "{0} sur {1} caractères autorisés"
        }
    });

})(jQuery.noConflict());
