/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: webapp-legacy
 * Version: 7.1.2
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function (jQuery) {

    window.LOCALE_FO = window.LOCALE_FO || {};

    jQuery.extend(true, window.LOCALE_FO, {
        search: {
            search_by_keyword: "Recherche par mots-clés",
            no_result: "Aucune proposition de résultat",
            wait_result: "Recherche de résultats...",
            query_too_short: "Taper 3 caractères ou plus pour obtenir des résultats.",
            selected_option: "L'option sélectionnée est surlignée.",
            assistive_hint: "Lorsque les résultats d'autocomplétion sont disponibles, utilisez les flèches haut et bas pour faire défiler et entrer pour sélectionner. Utilisateurs d'appareils tactiles, explorer par le toucher ou avec les gestes de balayage."
        }
    });
})(jQuery.noConflict());
