/**
 * Gestion des accordéons.
 *
 * @param $
 *            Contexte jQuery
 */
(function ($) {
    'use strict';
    $(function () {

        /**
         * Génération de l'identifiant unique des onglets.
         * @returns {string}
         */
        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }


        // Parcours de chaque ckeditor
        $('.ckeditor-accordion').each(function initAccordionCkeditor() {

            var $accordion = $(this);
            var $dt = $('> dt',$accordion);
            var $dd = $('> dd',$accordion);
            
            $dt.addClass("accordion_toggler");
            $dd.addClass("accordion_wrapper");

            // On positionne .actif sur le premier champ (par défaut)
            //$dt.first().addClass('active');
            //$dd.first().addClass('active');

            // Init Target Dt (positionnement du a href="#uuid)
            $dt.each(function initAccordionCkeditorDt() {
                var uuid = "a"+uuidv4(); //un ID doit toujours commencer par une lettre pour etre valide
                $(this).wrapInner('<button class="accordion_toggler__btn" target="#'+uuid+'"></button>');
                $(this).next().attr('id',uuid);
            });

            $('.accordion_toggler__btn', $accordion).on('click', function(){
                var target = $(this).attr('target');
                //$('.accordion_toggler, .accordion_wrapper', $accordion).removeClass('active');  //activer pour avoir la fermeture automatique des autres onglets au clic
                $(this).parent('.accordion_toggler').toggleClass('active');
                $(target).toggleClass('active');
            });

        });
    });
})(jQuery.noConflict());