(function($) {
    function matches(el, selector) {
        if (!el) {
            return false;
        }
        var matchesCallable = (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector);
        if (!matchesCallable) {
            return false;
        }
        return matchesCallable.call(el, selector);
    }

    function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    }

    function parentMatches(el, selector) {
        return closest(el, function(e) {
            return matches(e, selector);
        }) != null;
    }

    function ancestorIndex(el, selector) {
        var index = 0;
        while (el !== null && !matches(el, selector)) {
            el = el.parentNode;
            index++;
        }
        if (el === null) {
            return null;
        } else {
            return index;
        }
    }

    // Data-toggle init
    (new DataToggle({
        preventingCallback: function(event, $element, $target, $el) {
            /*
             * L'apposition de l'attribut data-toggle-target sur un item
             * empêche le déclenchement d'un data-toggle englobant.
             *
             * Par exemple, pour fermer un dropdown ouvert, on doit poser
             * un data-toggle sur le body. Or, comme le body englobe
             * le dropdown, le clic à l'intérieur de la zone du dropdown
             * a pour effet de le fermer, ce qui n'est pas souhaitable.
             * En ajoutant l'attribut data-toggle-target sur le dropdown,
             * on empêche donc l'exécution du mécanisme de data-toggle
             * à l'intérieur de celui-ci.
             *
             * Toutefois, il peut y avoir besoin d'ajouter des
             * data-toggle à l'intérieur d'un dropdown. C'est pourquoi,
             * il est nécessaire de contrôler que si le clic se trouve
             * dans une zone "restreinte" par le data-toggle-target, il
             * faut quand même autoriser l'action du data-toggle. Cela
             * peut donc se déduire, grâce à l'arborescence du HTML : si
             * le parent le plus proche de l'élément sur lequel est
             * intervenu le clic est un data-toggle et non un
             * data-toggle-target, alors on peut autoriser l'action du
             * data-toggle.
             */
            if (parentMatches($el, '[data-toggle-group-keep].active')) { // prevent close of tab contents)
                if (!matches($el, '[data-toggle-allow-default]')) {
                    event.preventDefault();
                }
                return true;
            }
            if (parentMatches($el, '[data-toggle-target]')) {
                var parentDataToggleTarget = ancestorIndex($el, '[data-toggle-target]');
                var parentDataToggle = ancestorIndex($el, '[data-toggle]');
                if (parentDataToggle === null) {
                    return true;
                } else {
                    return parentDataToggleTarget < parentDataToggle;
                }
            }
            return false;
        }
    }));

    // Owl Carousel
    $('.owl-carousel:not(.owl-carrousel-slideshow)').each(function() {
        var $t = $(this);
        var willBeAnimated = $t.find('.item').length > 1;
        $t.owlCarousel({
            items: 1,
            margin: 10,
            loop: willBeAnimated,
            nav: false,
            autoplay: willBeAnimated,
            mouseDrag: willBeAnimated,
            touchDrag: willBeAnimated,
            autoplayTimeout: 8000,
            autoplayHoverPause: true
        });
        $t.nextAll('.owl-controls').prepend('<button class="owl-prev">&lt;</button>').append('<button class="owl-next">&gt;</button>');
        $t.nextAll('.owl-controls').find('.owl-prev').click(function(){
            $t.trigger('prev.owl.carousel');
        });
        $t.nextAll('.owl-controls').find('.owl-next').click(function(){
            $t.trigger('next.owl.carousel');
        });
        $t.nextAll('.owl-controls').insertAfter($t.children().last());
    });

    // Give focus to controls inside top-bar menus when they are opened
    [].forEach.call(document.querySelectorAll('.header-toggle__menu'), function(element) {
        element.addEventListener('give-focus', function() {
           var inputs = element.querySelectorAll('input:not([type=hidden]),select,textarea,button,a[href]');
           if (inputs.length > 0) {
               inputs[0].focus();
           }
        });
    });

    // Share actions
    $('.js-share-action').on('click', function(e){
        e.preventDefault();
        var href = $(this).attr('href');
        window.open(href, '', 'height=400,width=600,titlebar=yes');
        return false;
    });

    // Datagrid des résultats de recherche de formations
    var $resultatSelecteur = $("#resultatSelecteur");
    if ($resultatSelecteur.length > 0) {
        $resultatSelecteur.dataTable();
    }

    //Soumission de la recherche lors de la selection d'une valeur proposée par l'autocomplete
    $(document).on("change", "#MOTS_CLEFS_AUTOCOMPLETE[data-selectedvalue]", function(){
        $(this).closest("form").submit();
    });
})(jQuery.noConflict());
