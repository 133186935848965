/*
 * This is a i18n.locale language object.
 *
 * French translation by Luc Poupard, Twitter: @klohFR
 * @author
 *   Luc Poupard (Twitter: @klohFR)
 *
 * @see
 *   me-i18n.js
 *
 * @params
 *  - exports - CommonJS, window ..
 */
;(function(exports, undefined) {

    "use strict";

    if (typeof exports.fr === 'undefined') {
        exports.fr = {
            "Download File" : "Télécharger le fichier",
            //"Play/Pause" : "Lecture/Pause",
            "Play" : "Lecture",
            "Pause" : "Pause",
            "Mute Toggle" : "Activer/désactiver le son",
            "Fullscreen" : "Plein écran",
            "Captions/Subtitles" : "Sous-titres",
            "None" : "Aucun",
            "Go Fullscreen" : "Afficher en plein écran",
            "Turn off Fullscreen" : "Quitter le mode plein écran",
            "Unmute" : "Activer le son",
            "Mute" : "Désactiver le son",
            "Download Video" : "Télécharger la vidéo",
            "Close" : "Fermer",
            "Use Up/Down Arrow keys to increase or decrease volume." : "Utilisez les touches Haut/Bas pour augmenter ou baisser le volume."
        };
    }

}(mejs.i18n.locale.strings));