(function($){   
	var devicesList = [ // Liste des devices proposés
      {
	    "name"  : "iphone",
	    "label" : window.RESPONSIVE_PREVISUALIZATION.messages.device_mobile_portrait,
	    "width" : "320",
	    "height" : "480"
	  },
	  {
		"name"  : "iphone-l",
	    "label" : window.RESPONSIVE_PREVISUALIZATION.messages.device_mobile_landscape,
	    "width" : "480",
	    "height" : "320"
	  },
	  {
		"name"  : "ipad",
	    "label" : window.RESPONSIVE_PREVISUALIZATION.messages.device_tablet_portrait,
	    "width" : "768",
	    "height" : "1024"
	  }, 
	  {
		"name"  : "ipad-l",
	    "label" : window.RESPONSIVE_PREVISUALIZATION.messages.device_tablet_landscape,
	    "width" : "1024",
	    "height" : "768"
	  }, 
	  {
		"name"  : "desktop",
	    "label" : window.RESPONSIVE_PREVISUALIZATION.messages.device_desktop,
	    "width" : "1200",
	    "height" : "800"
	  } 
	];	
	var message1 = window.RESPONSIVE_PREVISUALIZATION.messages.resolution;
	var message2 = window.RESPONSIVE_PREVISUALIZATION.messages.disclaimer;
	var defaultDeviceIndex = 0; //index du device selectionné par défaut
	var modalId = "responsive_modal";
	var wrapperId = "responsive_wrapper";
    var iframeId = "responsive_iframe";
    var viewportId = "viewport";
    var tooltipId = "responsive_tooltip";
    var devicesMenuId = "devices_list";
    var deviceItemCssClass = "device_item";
    var deviceActiveCssClass = "active";
    var modalCssClass = "has_modal";
    var closeItemCssClass = "close_previsualization";
    var messageCssClass = "viewport_message_intro";
	
    function loadResponsivePrevisualization(){
    	
    	/**************
		 * Construction du module
		 **************/

		//Récupération du device par défaut
		var selectedDevice = devicesList[defaultDeviceIndex];
		
		//Creation des éléments de la zone de prévisualisation
		var responsiveWrapper = "<div id='"+modalId+"'><div id='"+wrapperId+"'></div></div>";
		
		//Creation du menu de selection des devices
		var devicesMenu = $("<ul id='"+devicesMenuId+"'></ul>");
		
		//Creation de l'iframe
		var iframe = "<div id='viewport_wrapper'><div class='viewport_content'><div id='viewport' class='"+selectedDevice.name+"'><iframe id='"+iframeId+"' name='responsiveframe' height='"+selectedDevice.height+"' width='"+selectedDevice.width+"' src=''></iframe></div><div class='device-detail'></div></div></div>";
		
		//Creation de la tooltip
		var tooltip = "<div id='"+tooltipId+"'><span class='"+messageCssClass+"'></span><span class='viewport_message_disclaimer'>"+message2+"</span></div>";
		
		//Ajout du wrapper dans le body
		$(body).addClass(modalCssClass).prepend(responsiveWrapper);
		
		//ajout de l'iframe, du menu et du message dans le viewport
		$(getId(wrapperId)).prepend(iframe).prepend(devicesMenu).prepend(tooltip);
		
		//Ajout des devices au menu
		devicesMenu.append("<li class='"+closeItemCssClass+"'><button><span class='icon icon-close'></span><span>"+window.RESPONSIVE_PREVISUALIZATION.messages.close+"</span></button></li>");
		for(var i = 0, l = devicesList.length; i < l; i++){
		  var currentDevice = devicesList[i];
		  devicesMenu.append("<li id='"+currentDevice.name+"' class='" + getItemCssClass(i) + " device'><button class='"+deviceItemCssClass+"' title='"+currentDevice.label+"' data-item='"+i+"'>" +currentDevice.name +"</button></li>");
		}
		
		//Generation de l'url de previsualisation avec un parametre random supplémentaire pour eviter l'interdiction d'affichage de la même url recursivement sur FF, IE
		var currentUrl = window.location.href;
		var randomParameter = "previsualisation="+new Date().getTime();
		
		//Ajout d'un parametre aleatoire
		if(currentUrl.indexOf('?')==-1) {
	        var currentUrl = currentUrl+"?"+randomParameter;
	    }else{
	        var currentUrl = currentUrl+"&"+randomParameter;
	    }
		
		//Injection dans l'iframe de l'url courante
		$(getId(iframeId)).attr("src", currentUrl);
		
		//Mise à jour du tooltip
		updateTooltip(selectedDevice);
		
		
		/**************
		 * Gestion des Evenements
		 **************/
		
		//Gestion du click sur un device
		$(getClass(deviceItemCssClass)).on('click', function() {

			//récuperation de l'index de l'item selectionné
			var selectedDevice = devicesList[$(this).attr("data-item")];
			
			var devicesMenu = $(getId(devicesMenuId));
			
			//RAZ des styles
			$(devicesMenu).find("li").removeClass(deviceActiveCssClass);
			
			//Selection de l'item cliqué
			$(devicesMenu).find(getId(selectedDevice.name)).addClass(deviceActiveCssClass);
			
			//Modification de la classe sur le viewport
			$(getId(viewportId)).removeClass().addClass(selectedDevice.name);
			
			//Modification des dimensions de l'iframe
			$(getId(iframeId)).attr("width",selectedDevice.width).attr("height",selectedDevice.height);

			//Mise à jour du tooltip
			updateTooltip(selectedDevice);
		});
		
		//Gestion de la fermeture du viewport
		$(getClass(closeItemCssClass)).on('click', function() {
			$(getId(modalId)).remove();
			$(body).removeClass(modalCssClass)
		});

	}
	
	function getItemCssClass(index){
		if(index === defaultDeviceIndex){
			return deviceActiveCssClass;
		}
		return "";
	}
	
	function getId(selector){
		return "#"+selector; 
	}
	
	function getClass(selector){
		return "."+selector; 
	}
	
	function updateTooltip(selectedDevice){
		var message = message1 + " <b>" + selectedDevice.width +"</b> x <b>"+selectedDevice.height + "</b> ("+ selectedDevice.label + ")";
		$(getClass(messageCssClass)).html(message);
	}
	
	//Initialisation
	$(".actions-fiche__item--window.RESPONSIVE_PREVISUALIZATION").click(function() {
		loadResponsivePrevisualization();
	});
	
})(jQuery.noConflict());