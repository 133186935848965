/**
 * Gestion des onglets.
 *
 * @param $
 *            Contexte jQuery
 */
(function ($) {
    'use strict';
    
    jQuery.fn.reverse = [].reverse;
    
    /**
     * Génération de l'identifiant unique des onglets.
     * @returns {string}
     */
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    $(function () {

        // Parcours de chaque ckeditor
        $('.ckeditor-tabs').each(function initTabCkeditor() {
            var $tabs = $(this);
            var $dt = $('> dt',$tabs);
            var $dd = $('> dd',$tabs);
            
            $dt.addClass("tabs_toggler");
            $dd.addClass("tabs_wrapper");

            // On positionne .actif sur le premier champ (par défaut)
            $dt.first().addClass('active');
            $dd.first().addClass('active');

            // Init Target Dt (positionnement du a href="#uuid)
            $dt.each(function initTabCkeditorDt() {
                var uuid = "a"+uuidv4(); //un ID doit toujours commencer par une lettre pour etre valide
                var toggler = '<button class="tabs_toggler__btn" target="'+uuid+'"></button>';
                $(this).addClass(uuid).wrapInner(toggler);
                $(this).next().attr('id',uuid);
            });
            
            $dt.reverse().each(function initTogglerDesktop() {
                $(this).parent().prepend($(this).clone().addClass("desktop-only"));
            });

            $('.tabs_toggler__btn', $tabs).on('click', function(){
                var target = $(this).attr('target');
                $('.tabs_toggler, .tabs_wrapper', $tabs).removeClass('active');
                $("#"+target+", ."+target).addClass('active');
            });

        });
    });
})(jQuery.noConflict());