// Script de gestion des favoris utilisateurs.
(function () {
    // Identifiant du cookie contenant les favoris.
    const cookieFavorisIdentifiant = 'favoris';
    // Identifiant du cookie contenant les réponses au quiz.
    const cookieReponsesQuizIdentifiant = 'reponsesQuiz';
    // Identifiant du cookie contenant le statut au quiz.
    const cookieStatutQuizIdentifiant = 'quiz';
    // Identifiant du cookie permettant de savoir s'il faut envoyer le quiz.
    const cookieStatutEvoyerQuizIdentifiant = 'envoyerQuiz';
    // Les boutons permettant d'ajouter un favori.
    const boutonsAjouterFavoris = Array.from(document.getElementsByClassName('js-favoris-ajouter'));
    // Les boutons permettant de supprimer un favori.
    const boutonsSupprimerFavoris = Array.from(document.getElementsByClassName('js-favoris-supprimer'));
    // Les boutons d'accès au récapitulatif des favoris.
    const boutonsRecapitulatif = Array.from(document.getElementsByClassName('js-favoris-recapitulatif'));
    // La dialog de confirmation de vidage de tous les favoris.
    const dialogVider = document.querySelector('.js-favoris-vider-dialog');
    // Boutons permettant supprimer tous les favoris.
    const boutonsConfirmerViderFavoris = Array.from(document.getElementsByClassName('js-favoris-vider-confirmer'));
    // Boutons permettant supprimer tous les favoris.
    const boutonsAnnulerViderFavoris = Array.from(document.getElementsByClassName('js-favoris-vider-annuler'));
    // Boutons permettant supprimer tous les favoris.
    const boutonsViderFavoris = Array.from(document.getElementsByClassName('js-favoris-vider'));
    // Liste des boutons permettant de demander l'envoie par mail.
    const boutonsEnvoyerQuiz = Array.from(document.getElementsByClassName('js-envoyer-quiz'));
    // Ajout des évènements d'ajout.
    boutonsAjouterFavoris.forEach(boutonAjouter => {
        boutonAjouter.addEventListener('click', (event) => {
            let favoris = recupererValeurCookieFavoris();
            let identifiant = event.currentTarget.dataset.identifiantFavori;
            miseAJourCookie(ajouterFavori(favoris, identifiant));
            mettreAJourCompteurs();
            // Affichage des boutons de suppression et masquage des boutons d'ajout. On cible seulement les boutons avec le même identifiant que celui ciblé.
            Array.from(document.querySelectorAll('.js-favoris-ajouter[data-identifiant-favori="' + identifiant + '"]')).forEach(e => e.classList.add('hide'));
            Array.from(document.querySelectorAll('.js-favoris-supprimer[data-identifiant-favori="' + identifiant + '"]')).forEach(e => e.classList.remove('hide'));
        });
    });
    // Ajout des évènements de suppression.
    boutonsSupprimerFavoris.forEach(boutonSupprimer => {
        boutonSupprimer.addEventListener('click', (event) => {
            let favoris = recupererValeurCookieFavoris();
            let identifiant = event.currentTarget.dataset.identifiantFavori;
            miseAJourCookie(supprimerFavori(favoris, identifiant));
            mettreAJourCompteurs();
            // Affichage des boutons de d'ajout et masquage des boutons de suppression. On cible seulement les boutons avec le même identifiant que celui ciblé.
            Array.from(document.querySelectorAll('.js-favoris-ajouter[data-identifiant-favori="' + identifiant + '"]')).forEach(e => e.classList.remove('hide'));
            Array.from(document.querySelectorAll('.js-favoris-supprimer[data-identifiant-favori="' + identifiant + '"]')).forEach(e => e.classList.add('hide'));
        });
    });
    // Ajout des évènements pour vider les favoris.
    boutonsViderFavoris.forEach(boutonVider => {
        boutonVider.addEventListener('click', () => {
            dialogVider.showModal();
        });
    });
    // Ajout des évènements pour annuler le vidage des favoris.
    boutonsAnnulerViderFavoris.forEach(boutonAnnuler => {
        boutonAnnuler.addEventListener('click', () => {
            dialogVider.close();
        });
    });
    // Ajout des évènements pour confirmer le vidage des favoris.
    boutonsConfirmerViderFavoris.forEach(boutonConfirmer => {
        boutonConfirmer.addEventListener('click', () => {
            miseAJourCookie('');
            supprimerCookiesQuiz();
            window.location.reload();
        });
    });
    // Ajout des évènements sur les boutons d'envoie du quiz.
    boutonsEnvoyerQuiz.forEach(boutonEnvoyer => {
        boutonEnvoyer.addEventListener('click', (e) => {
            e.preventDefault();
            miseAJourCookieEnvoyerQuizz(true);
            window.location = e.target.href;
        });
    })
    // Récupération du premier élément trouvé d'ajout aux favoris pour savoir l'identifiant manipulé. Permet de déterminer le bouton à afficher lors du chargement de la page.
    if (boutonsAjouterFavoris.length > 0) {
        let premierBouton = document.querySelector('.js-favoris-ajouter');
        if (premierBouton) {
            let favoris = recupererValeurCookieFavoris();
            if (favoris.indexOf(premierBouton.dataset.identifiantFavori) > -1) {
                boutonsSupprimerFavoris.forEach(e => e.classList.remove('hide'));
            } else {
                boutonsAjouterFavoris.forEach(e => e.classList.remove('hide'));
            }
        }
    }
    mettreAJourCompteurs();

    /**
     * Ajout d'un identifiant aux favoris.
     * @param favoris La liste des favoris.
     * @param identifiant L'identifiant.
     * @return {string} La nouvelle liste des favoris.
     */
    function ajouterFavori(favoris, identifiant) {
        // Ajout d'un identifiant, seulement s'il n'existe pas déjà dans les favoris.
        const index = favoris.indexOf(identifiant);
        if (index > -1) {
            return favoris;
        }
        favoris.push(identifiant);
        return favoris;
    }

    /**
     * Suppression d'un identifiant des favoris.
     * @param favoris La liste des favoris.
     * @param identifiant L'identifiant du favori à supprimer.
     * @return {string} La nouvelle liste des favoris.
     */
    function supprimerFavori(favoris, identifiant) {
        const index = favoris.indexOf(identifiant);
        if (index <= -1) {
            return favoris;
        }
        return favoris.filter(favori => favori !== identifiant);
    }

    /**
     * Récupération de la valeur d'un cookie.
     * @param cle La clé d'identification du cookie.
     * @return La valeur du cookie.
     */
    function recupererValeurCookie(cle) {
        if (!document.cookie.includes(cle + '=')) {
            return '';
        }
        let cookie = document.cookie.split(';').filter((item) => item.trim().startsWith(cle + '=')).pop();
        return cookie.replace(cle + '=', '');
    }

    /**
     * Récupération du cookie des favoris.
     * @return La liste des favoris.
     */
    function recupererValeurCookieFavoris() {
        let favoris = recupererValeurCookie(cookieFavorisIdentifiant);
        if (!favoris || favoris === ' ') {
            return [];
        }
        let json = atob(favoris);
        return JSON.parse(json);
    }

    /**
     * Mise à jour du cookie avec la valeur des favoris.
     * @param favoris Les favoris.
     */
    function miseAJourCookie(favoris) {
        const date = new Date();
        date.setTime(date.getTime() + (12 * 60 * 60 * 1000));
        let expires = 'expires=' + date.toUTCString();
        if (!favoris || favoris === ' ') {
            document.cookie = cookieFavorisIdentifiant + '=;' + 0 + ';path=/';
        } else {
            let json = JSON.stringify(favoris);
            let base64 = btoa(json);
            document.cookie = cookieFavorisIdentifiant + '=' + base64 + ';' + expires + ';path=/';
        }
    }

    /**
     * Mise à jour du cookie avec la valeur des favoris.
     * @param sauvegarderQuiz Les favoris.
     */
    function miseAJourCookieEnvoyerQuizz(sauvegarderQuiz) {
        const date = new Date();
        date.setTime(date.getTime() + (12 * 60 * 60 * 1000));
        let maxAge = 'expires=' + date.toUTCString();
        if (!sauvegarderQuiz || sauvegarderQuiz === ' ') {
            document.cookie = cookieStatutEvoyerQuizIdentifiant + '=;' + 0 + ';path=/';
        } else {
            document.cookie = cookieStatutEvoyerQuizIdentifiant + '=true;' + maxAge + ';path=/';
        }
    }

    /**
     * Suppression des cookies relatifs au quiz.
     */
    function supprimerCookiesQuiz() {
        miseAJourCookieEnvoyerQuizz(false);
        document.cookie = cookieReponsesQuizIdentifiant + '=;' + 0 + ';path=/';
        document.cookie = cookieStatutQuizIdentifiant + '=;' + 0 + ';path=/';
    }

    /**
     * Calcule le nombre de favoris.
     * @return {number} Le nombre de favoris sélectionnés.
     */
    function calculerNombreFavoris() {
        let favoris = recupererValeurCookieFavoris().length;
        // Le fait que le quiz possède des réponses correspond à un élément supplémentaire des favoris.
        let reponsesQuiz = recupererValeurCookie(cookieReponsesQuizIdentifiant);
        let envoyerQuiz = recupererValeurCookie(cookieStatutEvoyerQuizIdentifiant);
        if (reponsesQuiz && reponsesQuiz !== ' ' && envoyerQuiz && envoyerQuiz.trim() === 'true') {
            favoris++;
        }
        return favoris;
    }

    /**
     * Mise à jour des compteurs des favoris.
     */
    function mettreAJourCompteurs() {
        for (const bouton of boutonsRecapitulatif) {
            bouton.dataset.countBadge = calculerNombreFavoris();
        }
    }
})();