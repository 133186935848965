window.addEventListener('load', () => {
    // CSS helper - https://stackoverflow.com/a/66726233
    function _calculateScrollbarWidth() {
        document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px")
    }
    window.addEventListener('resize', _calculateScrollbarWidth, false)
    document.addEventListener('DOMContentLoaded', _calculateScrollbarWidth, false)
    _calculateScrollbarWidth()

    //PetiteVue
    function clickAwayDirective(ctx) {
        const { el, get } = ctx
        const listener = (event) => {
            if (!el.contains(event.target)) {
                get();
            }
        };

        document.addEventListener("click", listener);

        return () => {
            document.removeEventListener(listener);
        };
    }

    const menuReactive = PetiteVue.reactive({
        currentMenu: null,
        toggleMenu(menu, force = null) {
            if (force === true) {
                this.currentMenu = menu
            } else if (force === false) {
                this.currentMenu = ''
            } else {
                this.currentMenu = this.currentMenu === menu ? '' : menu;
            }
        },
        hideAll() {
            this.currentMenu = '';
        }
    });

    PetiteVue.createApp({ menuReactive }).directive("click-away", clickAwayDirective).mount();
})

// On DOM ready.
jQuery(function($) {
    var container = $('.fiche-metier-onisep__potentiel-embauche--img');
    var list = $('.fiche-metier-onisep__potentiel-embauche--legende');

    // Make body element track touch events.
    var $body = $(document.body).touchOrMouse('init');

    // Bind mouse events to links.
    container
        .click(myClickCallback)
        .mouseenter(myMouseenterCallback)
        .mouseleave(myMouseleaveCallback);

    // Click callback.
    function myClickCallback(e) {
        var touchOrMouse = $body.touchOrMouse('get', e);

        // If mouse event has been invoked by touch.
        if (touchOrMouse === 'touch') {
            // Toggle .hovered class.
            list.toggleClass('show-tooltip');
        }

        // Do not follow the link.
        e.preventDefault();
    }

    // Mouse enter callback.
    function myMouseenterCallback(e) {
        var touchOrMouse = $body.touchOrMouse('get', e);

        // If mouse event has not been invoked by touch.
        if (touchOrMouse === 'mouse') {
            // Add .hovered class.
            list.addClass('show-tooltip');
        }
    }

    // Mouse leave callback.
    function myMouseleaveCallback(e) {
        var touchOrMouse = $body.touchOrMouse('get', e);

        // If mouse event has not been invoked by touch.
        if (touchOrMouse === 'mouse') {
            // Remove .hovered class.
            list.removeClass('show-tooltip');
        }
    }

    $('.js-slideshow-card').each(function() {
        let $t = $(this);
        let hasMoreThanOneItem = $t.find('.js-owl-item-wrapper').length > 1;
        let willBeAnimated = $t.attr('data-autoplay') === 'true' && hasMoreThanOneItem;
        let controlDisplay = $t.attr('data-control-display') === 'true' && hasMoreThanOneItem;
        let hoverPause = $t.attr('data-hover-pause') === 'true' && willBeAnimated;
        let slideDuration = +($t.attr('data-slide-duration'));
        if (Number.isNaN(slideDuration) || slideDuration <= 0) {
            slideDuration = 5000;
        }
        let animationDuration = +($t.attr('data-animation-duration'));
        if (Number.isNaN(animationDuration) || animationDuration <= 0) {
            animationDuration = 200;
        }
        $t.owlCarousel({
            items: 1,
            margin: 10,
            loop: hasMoreThanOneItem,
            nav: controlDisplay,
            navText: [
                '<svg title="${ARASDIO.SLIDE_PRECEDENTE.TITLE}" alt="${ARASDIO.SLIDE_PRECEDENTE}" width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path style="transform: scaleX(-1); transform-origin: center;" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 11.5V13.5H16.5L11 19L12.42 20.42L20.34 12.5L12.42 4.58002L11 6.00002L16.5 11.5H4.5Z" fill="currentColor"/></svg>',
                '<svg title="${ARASDIO.SLIDE_SUIVANTE.TITLE}" alt="${ARASDIO.SLIDE_SUIVANTE}" width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 11.5V13.5H16.5L11 19L12.42 20.42L20.34 12.5L12.42 4.58002L11 6.00002L16.5 11.5H4.5Z" fill="currentColor"/></svg>'
            ],
            dots: false,
            autoplay: willBeAnimated,
            mouseDrag: hasMoreThanOneItem,
            touchDrag: hasMoreThanOneItem,
            autoplayTimeout: slideDuration,
            smartSpeed: animationDuration,
            autoplayHoverPause: hoverPause
        });
    });
});

(function(e){"use strict";var t={ghostEventDelay:1e3};var n={init:function(n){var r=this;if(!r.data("touchOrMouse")){r.on("touchstart.touchOrMouse touchend.touchOrMouse",function(t){e(this).data("touchOrMouse").touchEventInProgress=t.type==="touchstart";e(this).data("touchOrMouse").lastTouchEventTimestamp=Date.now()}).data("touchOrMouse",{options:e.extend(t,n||{}),touchEventInProgress:false,lastTouchEventTimestamp:0})}return r},get:function(t){var n=this,r=n.data("touchOrMouse"),i=e(t.delegateTarget).data("touchOrMouse");if(!i){e(t.delegateTarget).data("touchOrMouse",i={})}var s=r.touchEventInProgress||r.lastTouchEventTimestamp>Date.now()-r.options.ghostEventDelay||t.type==="mouseleave"&&i.lastMouseEnterEventWasInvokedByTouch;if(t.type==="mouseenter"){i.lastMouseEnterEventWasInvokedByTouch=s}return s?"touch":"mouse"}};e.fn.touchOrMouse=function(e,t){return n[e].call(this,t)}})(jQuery);
