window.RESPONSIVE_PREVISUALIZATION = {
    messages: {
        device_mobile_portrait: "Mobile en mode portrait",
        device_mobile_landscape: "Mobile en mode paysage",
        device_tablet_portrait: "Tablette en mode portrait",
        device_tablet_landscape: "Tablette en mode paysage",
        device_desktop: "Desktop",
        resolution: "Prévisualisation du site en résolution",
        disclaimer: "Cliquez sur un type de terminal pour obtenir une prévisualisation du site.<br/><br/>Le rendu réel est susceptible de varier en fonction des spécificités du mobile utilisé ainsi que du navigateur employé pour la consultation",
        close: "Fermer"
    }
};