/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: webapp-contrib
 * Version: 7.1.2
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function() {
    'use strict';

    // utilitaire permettant de convertir un tableau de données au format choices
    var group_separator = ';;';
    var item_separator = ';';
    function convert(datas) {
        return (datas || []).map(function (data) {
            //Truc tordu pour corriger FORMATION-304 (group_separator dans le value)
            if (data.data.groupId) {
                return {value: data.data.code, label: data.value + group_separator + data.data.groupId};
            } else {
                return {value: data.data.code, label: data.value};
            }
        });
    }

    // utilitaire permettant de tagger un mot-clé dans un label
    function tag_keyword(label, tag) {
        var low = tag.toLowerCase();
        var cap = low.charAt(0).toUpperCase() + low.substring(1, low.length);
        return label.replace(low, '<keyword>' + low + '</keyword>').replace(cap, '<keyword>' + cap + '</keyword>');
    }

    var autocompletes = document.querySelectorAll('.js-autocomplete');
    [].forEach.call(autocompletes, function (elt) {

        // récupération des variables du composant
        var data_id = elt.getAttribute('data-id');
        var data_bean = elt.getAttribute('data-bean');
        var data_search_floor = parseInt(elt.getAttribute('data-search-floor'),10);
        var data_max_item_count = parseInt(elt.getAttribute('data-max-item-count'),10);
        var data_autocompleteurl = elt.getAttribute('data-autocompleteurl');
        var data_flip = elt.getAttribute('data-flip') === 'true';

        // récupération de la locale
        var data_locale = elt.getAttribute('data-locale');

        // récupération des messages du composant
        var data_no_results_text = elt.getAttribute('data-no-results-text'),
            data_no_choices_text = elt.getAttribute('data-no-choices-text'),
            data_item_select_text = elt.getAttribute('data-item-select-text'),
            data_item_remove_text = elt.getAttribute('data-item-remove-text'),
            data_max_item_count_text = elt.getAttribute('data-max-item-count-text');

        // sauvegarde du mot-clé saisi
        var keyword = document.querySelector('.' + data_id + '-keyword'),
            choicesOptions = {
                placeholder: true,
                placeholderValue: '',
                loadingText: '',
                noResultsText: data_no_results_text,
                noChoicesText: data_no_choices_text,
                itemSelectText: data_item_select_text,
                searchFloor: data_search_floor,
                maxItemCount: data_max_item_count,
                removeItemButton: true,
                duplicateItems: true,
                searchChoices:false,
                flip: data_flip,
                shouldSort: false,
                callbackOnCreateTemplates: function (template) {
                    var itemSelectText = this.config.itemSelectText;
                    var classNames = this.config.classNames;
                    return {
                        item: function (data) {
                            var frg = data.label.split(group_separator);
                            if (frg.length > 1) {
                                return template('<div class="' + classNames.item + ' ' + (data.highlighted ? classNames.highlightedState : classNames.itemSelectable) + '" data-item data-id="' + data.id + '" data-value="' + data.value + '" ' + (data.active ? 'aria-selected="true"' : '') + ' ' + (data.disabled ? 'aria-disabled="true"' : '') + '><span>' + frg[0] + ' (' + frg[1] + ')</span><button data-button="" class="choices__button">' + data_item_remove_text + '</button></div>');
                            }
                            return template('<div class="' + classNames.item + ' ' + (data.highlighted ? classNames.highlightedState : classNames.itemSelectable) + '" data-item data-id="' + data.id + '" data-value="' + data.value + '" ' + (data.active ? 'aria-selected="true"' : '') + ' ' + (data.disabled ? 'aria-disabled="true"' : '') + '><span>' + frg[0] + '</span><button data-button="" class="choices__button">' + data_item_remove_text + '</button></div>');
                        },
                        choice: function (data) {
                            var frg = data.label.split(group_separator);
                            if (frg.length > 1) {
                                var item = tag_keyword(frg[0] + ' (' + frg[1] + ')', keyword.value);
                                return template('<div class="' + classNames.item + ' ' + classNames.itemChoice + ' ' + (data.disabled ? classNames.itemDisabled : classNames.itemSelectable) + '" data-select-text="' + itemSelectText + '" ' + 'data-choice ' + (data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable') + ' data-id="' + data.id + '" data-value="' + data.value + '" ' + (data.groupId > 0 ? 'role="treeitem"' : 'role="option"') + '><group>' + item + '</group></div>');
                            }
                            return template('<div class="' + classNames.item + ' ' + classNames.itemChoice + ' ' + (data.disabled ? classNames.itemDisabled : classNames.itemSelectable) + '" data-select-text="' + itemSelectText + '" ' + 'data-choice ' + (data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable') + ' data-id="' + data.id + '" data-value="' + data.value + '" ' + (data.groupId > 0 ? 'role="treeitem"' : 'role="option"') + '><span>' + tag_keyword(frg[0], keyword.value) + '</span></div>');
                        }
                    };
                },
                callbackOnInit: function () {
                    // initialisation du composant
                    var eltLibel = document.querySelector('.js-autocomplete-libelle[data-id="' + data_id + '"]');
                    var dataJSON = eltLibel.value;
                    var parseResult;
                    try {
                        parseResult = JSON.parse(dataJSON);
                    } catch (e) {
                        // continue
                    }
                    var suggestions;
                    if (parseResult) {
                        suggestions = parseResult.suggestions;
                    } else {
                        suggestions = [];
                    }
                    var items = convert(suggestions);
                    this.setValue(items);
                    //Une evol de la libraire fait qu'on est obligé de faire comme ça
                    for ( var i = 0; i < items.length ; ++i ) {
                        var code = items[i].value;
                        if ('' === elt.value) {
                            elt.value = code;
                        } else {
                            elt.value = elt.value + item_separator + code;
                        }
                    }
                }
            };
        if (data_max_item_count_text) {
            choicesOptions.maxItemText = function (maxItem) {
                return data_max_item_count_text.replace('%s', maxItem);
            };
        }
        // déclaration du composant
        var autocomplete = new Choices('.' + data_id + '-autocomplete', choicesOptions);


        function callbackOnSearch (event) {
            var value = event.detail.value;
            //Creation de variable de comparaison pour eviter d'exécuter la recherche si le mot clé ne bouge pas
            var keyOne = data_bean + keyword.value;
            var keyTwo = data_bean + value;
            // exécution de la recherche si le mot-clé saisi diffère du précédent
            if (keyOne !== keyTwo) {
                var component = autocomplete;
                autocomplete.ajax(function() {
                    // la locale est incluse dans le mot-clé de recherche pour tromper le cache du service CacheAutoCompletionManager#traiterRecherche
                    fetch(data_autocompleteurl + 'BEAN_AUTO_COMPLETION=' + data_bean + '&query=' + value + group_separator + data_locale)
                        .then(function(response) {
                            response.json().then(function(datas) {
                                var items = convert(datas.suggestions);
                                component.setChoices(items, 'value', 'label', true);
                            });
                        });
                });
                // sauvegarde du mot-clé de recherche
                keyword.value = value;
            }
        }


        function createItem(code, libelle, groupValue) {
            var item = {'value':'','data':{'code':''},'forced':true};
            item.value = libelle;
            item.data.code = code;
            if (groupValue) {
                item.data['groupId'] = groupValue;
            }
            return item;
        }

        function buildArrayOfSelectedElement(eltLibel) {
            var parseResult;
            try {
                parseResult = JSON.parse(eltLibel.value);
            } catch (e) {
                // continue
            }
            if (!parseResult) {
                parseResult = {'suggestions':[]};
            }
            return parseResult;
        }


        function callbackOnAddItem(event) {
            var value = event.detail.value;
            if (value) {
                var code = value;
                var libelle = event.detail.label;
                var groupId = event.detail.groupValue;

                if ('' === elt.value) {
                    elt.value = code;
                } else {
                    elt.value = elt.value + item_separator + code;
                }
                //Truc pour corriger FORMATION-304 : aka la malediction de l'infoBean et des soumissions pour chaque onglet
                var item = createItem(code, libelle, groupId);
                // initialisation du composant
                var eltLibel = document.querySelector('.js-autocomplete-libelle[data-id="' + data_id + '"]');
                var parseResult = buildArrayOfSelectedElement(eltLibel);
                parseResult.suggestions.push(item);
                eltLibel.value = JSON.stringify(parseResult);
                //Fin tordu
            }
        }

        function callbackOnRemoveItem (event) {
            var value = event.detail.value;
            if (value) {
                var code = value;

                if (-1 === elt.value.indexOf(item_separator)) {
                    elt.value = elt.value.replace(code, '');
                } else if (0 === elt.value.indexOf(code + item_separator)) {
                    //Cas premier element
                    elt.value = elt.value.replace(code + item_separator, '');

                } else {
                    //Cas autre
                    elt.value = elt.value.replace(item_separator + code, '');
                }

                //Truc pour corriger FORMATION-304 : aka la malediction de l'infoBean et des soumissions pour chaque onglet
                var eltLibel = document.querySelector('.js-autocomplete-libelle[data-id="' + data_id + '"]');
                var parseResult = buildArrayOfSelectedElement(eltLibel);
                parseResult.suggestions.splice(event.detail.id - 1 , 1);
                eltLibel.value = JSON.stringify(parseResult);
                //Fin truc
            }
        }

        autocomplete.passedElement.addEventListener('addItem', callbackOnAddItem);
        autocomplete.passedElement.addEventListener('removeItem', callbackOnRemoveItem);
        autocomplete.passedElement.addEventListener('search', callbackOnSearch);

    });

})();